import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["src", "alt", "width", "height", "unoptimized", "loader"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as views from './Image.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var imageLoader = function imageLoader(_ref) {
  var src = _ref.src;
  return "".concat(src);
};

export var Image = function Image(_ref2) {
  var src = _ref2.src,
      alt = _ref2.alt,
      width = _ref2.width,
      height = _ref2.height,
      _ref2$unoptimized = _ref2.unoptimized,
      unoptimized = _ref2$unoptimized === void 0 ? true : _ref2$unoptimized,
      loader = _ref2.loader,
      props = _objectWithoutProperties(_ref2, _excluded);

  return _jsx(views.StyledImage, _objectSpread(_objectSpread({
    loader: loader || imageLoader,
    width: width,
    height: height,
    src: src,
    alt: alt
  }, props), {}, {
    unoptimized: loader ? false : unoptimized
  }));
};