import { useEffect, useState } from 'react';
import { getCookie } from '@segment/tracking/scripts/cookies';

var useTrustArcLink = function useTrustArcLink() {
  var _useState = useState(false),
      trustArcEnabled = _useState[0],
      setTrustArcEnabled = _useState[1];

  useEffect(function () {
    var wt = window;

    if (wt.truste) {
      var consent = getCookie("notice_behavior");

      if (consent !== "none") {
        setTrustArcEnabled(true);
      }
    }
  }, []);
  return trustArcEnabled;
};

export default useTrustArcLink;